import ActionNode = require("Everlaw/UI/ActionNode");
import Base = require("Everlaw/Base");
import C = require("Everlaw/Constants");
import DateUtil = require("Everlaw/DateUtil");
import Dialog = require("Everlaw/UI/Dialog");
import Dom = require("Everlaw/Dom");
import Duration = require("Everlaw/Duration");
import Icon = require("Everlaw/UI/Icon");
import Is = require("Everlaw/Core/Is");
import LabeledIcon = require("Everlaw/UI/LabeledIcon");
import Project = require("Everlaw/Project");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");
import SbFree = require("Everlaw/Context/SbFree");
import Str = require("Everlaw/Core/Str");
import { SystemPermission } from "Everlaw/SystemPermission";
import Tooltip = require("Everlaw/UI/Tooltip");
import User = require("Everlaw/User");
import Util = require("Everlaw/Util");
import { SftpDialog } from "Everlaw/SftpDialog";
import { AWSRegion } from "Everlaw/Server";
import { addToastWrapper } from "Everlaw/ToastBoxManager";
import { ToastType } from "design-system";
import { whenPageVisible } from "Everlaw/DojoUtil";

class Task extends Base.Object {
    get className() {
        return "Task";
    }

    static ERROR = "ERROR";
    static SCHEDULED = "SCHEDULED";
    static INITIALIZED = "INITIALIZED";
    static RUNNING = "RUNNING";
    static UNDONE = "UNDONE";
    static PAUSED = "PAUSED";
    static COMPLETED = "COMPLETED";
    static STOPPED = "STOPPED";

    // Tasks that can show an error message, but still be shown as complete
    // Currently shown in the footer of the Batches & Exports card
    static COMPLETE_ERROR_TASK_TYPES = ["BatchRedactionTask"];
    // hasFile does not mean these tasks are export types, but do have a file to download.
    static DOWNLOADABLE_NON_EXPORT_TASK_TYPES = ["BatchRedactionTask", "BatchDeleteRedactionsTask"];
    static DELETE_TASK_WITH_INFO_TYPES = ["DeleteSearchDocsTask"];
    static EXPORT_TASK_WITH_INFO_TYPES = [
        "DocumentExportTask",
        "ProjectExportTask",
        "ExportCSVTask",
        "ExportPDFTask",
        "ExportChronPDFTask",
        "ExportChronCSVTask",
        "ExportArgumentExhibitListTask",
        "ExportArgumentExhibitListZIPTask",
        "ExportDepositionTranscriptTask",
        "ExportDepositionHighlightClipTask",
        "ExportClusteringTermsCSVTask",
    ];
    static TASKS_WITH_DETAILS_HREF = {
        ProcessSearchTask: (task: Task): string => `/${task.project}/data.do#tab=processing-jobs`,
    };

    // These tasks will throw an exception if they're paused, so we should
    // disable the button!
    static UNPAUSEABLE_TASK_TYPES = ["AutomatedTesterTask", "TranscriptMediaUploadProcessingTask"];
    static ASYNC_INITIALIZED_TASK_TYPES = [
        "BatchLLMCodingSuggestions",
        "BatchLLMSummarize",
        "BatchLLMDocOverview",
        "BatchLLMCustomExtraction",
    ];

    // SFTP task disable date
    static SFTP_DISABLE_TASK_BEFORE: number = (() => {
        switch (JSP_PARAMS.Server.region) {
            case AWSRegion.AP_SOUTHEAST_2_SYDNEY:
                return 1557507600000; // AU release time

            case AWSRegion.EU_CENTRAL_1_FRANKFURT:
            case AWSRegion.EU_WEST_2_LONDON:
                return 1557529200000; // EU release time

            case AWSRegion.US_EAST_1_N_VIRGINIA:
            case AWSRegion.US_WEST_1_N_CALIFORNIA:
            case AWSRegion.US_WEST_2_OREGON:
                return 1556942400000; // US release time

            case AWSRegion.CA_CENTRAL_1:
                return 1557536400000; // CA release time

            // If we expand to other regions, we should probably add more cases
            // above but this is a reasonable default.
            default:
                return 1556942400000; // US release time
        }
    })();

    // Data set from backend JSON
    override id: Task.Id;
    parcel: Parcel;
    user: User.Id;
    project: Project.Id;
    state: string;
    summary: string;
    details: string[];
    // true iff details is present and is a preview, not the full details present in the download
    detailPreviewFlag: boolean;
    errorMessage: string;
    created: number;
    finished: number;
    scheduled?: number;
    expiration: number;
    // undoable is true iff the backend Task class implements Undoable and not SpecialUndoable
    undoable: boolean;
    hasFile: boolean;
    isCloneProjectTask: boolean;
    eta: number;
    progress = 0;
    // Other fields
    undone = false;
    data: any;
    taskType: string;
    // output password will be undefined for most tasks. Defined in [DocumentExportTask, ProjectExportTask]
    outputPassword: string;
    numDocs: number;
    billableSize: number;
    reason: string;
    uuid: string;
    successCount: number;
    errorCount: number;
    dependencyErrorCount: number;
    downloadFilename: string;
    downloadFileSize: number;
    totalUncompressedSize: number;
    autoCoded: boolean;
    custodianError: Task.CustodianError;
    detailsHref?: string;
    isAsyncTask?: boolean;
    // If this task type can have a error message but still be considered complete.
    private allowCompleteErrorMessage: boolean;
    private deleteTaskInfo: boolean;
    private exportTaskInfo: boolean;
    public readonly nonExportDownloadable: boolean;
    public readonly isPauseable: boolean;

    constructor(params: any) {
        super(params);
        this._mixin(params);
        this.allowCompleteErrorMessage = Task.COMPLETE_ERROR_TASK_TYPES.indexOf(this.taskType) >= 0;
        this.nonExportDownloadable =
            Task.DOWNLOADABLE_NON_EXPORT_TASK_TYPES.indexOf(this.taskType) >= 0;
        this.deleteTaskInfo = Task.DELETE_TASK_WITH_INFO_TYPES.indexOf(this.taskType) >= 0;
        this.exportTaskInfo = Task.EXPORT_TASK_WITH_INFO_TYPES.indexOf(this.taskType) >= 0;
        this.isPauseable = !(Task.UNPAUSEABLE_TASK_TYPES.indexOf(this.taskType) >= 0);
        this.detailsHref =
            this.taskType in Task.TASKS_WITH_DETAILS_HREF
                ? Task.TASKS_WITH_DETAILS_HREF[this.taskType](this)
                : null;
        this.isAsyncTask = Task.ASYNC_INITIALIZED_TASK_TYPES.indexOf(this.taskType) >= 0;
    }

    override _mixin(params: any) {
        Object.assign(this, params);
        if (params.custodianError) {
            this.custodianError = JSON.parse(params.custodianError);
        }
        if (this.details && this.detailPreviewFlag) {
            this.details[this.details.length - 1] = "…";
        }
    }
    override display() {
        return this.summary;
    }
    pause(callback?: (t: Task) => void) {
        this._update("pause", callback);
    }
    resume(callback?: (t: Task) => void) {
        this._update("resume", callback);
    }
    stop(callback?: (t: Task) => void) {
        this._update("stop", callback);
    }
    override compare(other: Task) {
        var idDiff = this.id - other.id;
        // We sort by active/finished
        // and then by time created/finished:
        // Newest running tasks
        // ...
        // Oldest running tasks
        // newest finished tasks
        // ..
        // oldest finished tasks
        if (this.isActive()) {
            return other.isActive() ? other.created - this.created || idDiff : -1;
        } else {
            return other.isActive() ? 1 : other.finished - this.finished || idDiff;
        }
    }
    displayETA() {
        if (this.state === Task.SCHEDULED) {
            return "Scheduled";
        }
        if (this.eta == null || this.state === Task.INITIALIZED) {
            return "Queued";
        } else if (this.eta < C.MIN) {
            return "<1m";
        } else {
            return Duration.HR_MIN.format(this.eta);
        }
    }
    displayParcel() {
        return this.parcel?.toString() || "";
    }
    hasDeleteTaskInfo() {
        return this.deleteTaskInfo && this.isFinished();
    }
    hasExportTaskInfo() {
        return this.exportTaskInfo && this.isFinished();
    }
    hasDetails() {
        return this.details && this.details.length > 0;
    }
    // Has a download, but is not an export task.
    hasDownloadableNonExport() {
        return this.nonExportDownloadable && this.canDownload();
    }
    hasCompletedErrorMessage() {
        return (
            this.allowCompleteErrorMessage
            && this.isFinished()
            && !this.isUndone()
            && !Str.isNullOrWhitespace(this.errorMessage)
        );
    }
    isActive() {
        return !this.isFinished() && !this.isScheduled();
    }
    isScheduled(): boolean {
        return this.state === Task.SCHEDULED;
    }
    isFinished() {
        return (
            this.state === Task.COMPLETED
            || this.state === Task.STOPPED
            || this.state === Task.ERROR
            || this.state === Task.UNDONE
        );
    }
    isPaused() {
        return this.state === Task.PAUSED;
    }
    isError() {
        return this.state === Task.ERROR;
    }
    isStopped() {
        return this.state === Task.STOPPED;
    }
    canDownload() {
        return this.state === Task.COMPLETED && this.hasFile;
    }
    canUndo() {
        return this.canAdmin() && this.undoable;
    }
    canAdmin() {
        if (User.me.id === this.user) {
            return true;
        }
        if (this.project) {
            return (
                User.me.has(SystemPermission.MANAGE_PROJECTS)
                || User.me.hasOrgAdminAccess(Base.get(Project, this.project))
            );
        }
        return User.me.has(SystemPermission.MANAGE_ADMIN_TASKS);
    }
    isUndoable() {
        return this.undoable;
    }
    isUndone() {
        return this.state === Task.UNDONE;
    }
    hasExpiration() {
        return this.canDownload() && !this.hasDownloadableNonExport() && this.expiration;
    }
    displayProgress() {
        return Math.min(99, Math.floor(this.progress * 100)) + "% (" + this.displayETA() + ")";
    }
    undoInner(callback?: (data: any) => void) {
        Task.createTask(
            `/parcel/${this.parcel}/tasks/undo.rest`,
            {
                id: this.id,
            },
            {
                success: (data) => {
                    this.undone = true;
                    this.state = Task.UNDONE;
                    Base.publish(this);
                    setTimeout(Task.loadTasks, 50);
                    callback && callback(data);
                },
            },
        );
        return true;
    }
    detailsLink() {
        return `/parcel/${this.parcel}/tasks/getDetails.rest?id=${this.id}`;
    }
    resultLink() {
        return `/parcel/${this.parcel}/tasks/getResult.rest?id=${this.id}`;
    }
    undo(callback?: (data: any) => void) {
        QueryDialog.create({
            title: "Undo this task?",
            prompt: [
                "Are you sure you want to undo this task?",
                Dom.p(
                    { style: "text-align: center", class: "h-spaced-8" },
                    new Icon("arrow-back-up").node,
                    Dom.span({ style: "font-style: italic" }, this.summary),
                ),
                "All changes that it made will be reverted (if possible).",
            ],
            submitText: "Undo",
            onSubmit: () => this.undoInner(callback),
        });
    }
    getPassword() {
        // Undefinied for most tasks
        return this.outputPassword;
    }
    createSFTPDialog() {
        return new SftpDialog({
            gaName: this.className,
            usernameUrl: `/parcel/${this.parcel}/tasks/getSftpUsername.rest`,
            credentialsUrl: `/parcel/${this.parcel}/tasks/setSftpCredentials.rest`,
            content: { id: this.id },
        });
    }
    private _update(action: string, callback?: (t: Task) => void) {
        Rest.post(`/parcel/${this.parcel}/tasks/update.rest`, {
            action: action,
            id: this.id,
        }).then((data) => {
            // will call our own mixin function
            this._mixin(data);
            Base.publish(this);
            callback && callback(this);
        });
    }
}

module Task {
    export type CustodianError = {
        hasError: boolean;
        totalCustodians: number;
        errorCount: number;
        service: string;
        sourceId: number;
    };

    export type UpdateInfo = {
        id: number;
        state: string;
        eta?: number;
        progress?: number;
        completionData?: any;
    };

    export enum TaskType {
        BatchRedaction = "BatchRedactionTask",
        UnitizeDocument = "UnitizeDocumentTask",
        BatchUnitization = "BatchUnitizationTask",
    }

    export type Id = number & Base.Id<"Task">;

    /**
     * There can be multiple calls to loadTasks, especially in the superuserpage where
     * both the WorkQueue tab and the TasksTables tab make a call.
     * If the load task is a simple one, there is no need to have an additional polling,
     * since it only requires Base to be updated.
     * Since any other loadTasks call will update Base,
     * there is no need to have an additional polling for a simple load task.
     *
     * complexPriorityLoadTasks tracks if we have a complex load task already.
     * simplePriorityLoadTask is set to return value of setTimeout of simpleLoad loadTasks.
     *   If not null, indicated there is already another simple load taking place.
     * We will stop the simple load if a complex load comes around.
     */
    let complexPriorityLoadTasks: boolean = false;
    let simplePriorityLoadTask: number = null;

    let lastFinished = 0;

    interface LoadTasksParams {
        onFirstLoad?: (data: any) => void;
        afterBase?: boolean;
        orgId?: number;
        simpleLoad?: boolean;
        taskClasses?: string[];
        ignorePId?: boolean;
        ignoreDBId?: boolean;
        maxTasks?: number;
        projectIds?: number[];
    }

    export function setFinishedCutoff(cutoff: number) {
        lastFinished = cutoff;
    }

    /**
     * Initiates a polling mechanism that asynchronously fetches the tasks in the current project
     * that are accessible by this user. After the first fetch, it reloads them periodically (more
     * frequently when tasks are running).
     *
     * @param onFirstLoad an optional callback that is performed after the first batch of tasks are
     * loaded.
     * @param afterBase optional, if false, onFirstLoad will be called before any calls to Base
     * (otherwise, it will be called after).
     * @param simpleLoad, default false. If true, will stop if there is another call to loadTasks.
     */
    export function loadTasks(loadTasksParams: LoadTasksParams) {
        const params = {
            onFirstLoad: null,
            afterBase: false,
            orgId: null,
            simpleLoad: false,
            taskClasses: [],
            ignorePId: false,
            ignoreDBId: true,
            maxTasks: null,
        };
        Object.assign(params, loadTasksParams);
        if (!params.simpleLoad) {
            complexPriorityLoadTasks = true;
        } else if (complexPriorityLoadTasks) {
            return;
        }
        if (simplePriorityLoadTask) {
            clearTimeout(simplePriorityLoadTask);
            simplePriorityLoadTask = null;
        }
        const url =
            Project.CURRENT && !(params.ignorePId && params.ignoreDBId)
                ? `/parcel/${Project.CURRENT.parcel}/tasks/get.rest`
                : "/tasks/get.rest";
        const content = {
            projectId: !params.ignorePId ? Project.CURRENT && Project.CURRENT.id : null,
            dbId: !params.ignoreDBId ? Project.CURRENT && Project.CURRENT.databaseId : null,
            // lastFinished + 1 would avoid re-fetching the most recent finished task, but would
            // risk skipping over other tasks that finished at the same time.
            since: lastFinished,
            // Just to avoid caching...
            now: Date.now(),
            orgId: params.orgId,
            taskClasses: params.taskClasses,
            maxTasks: params.maxTasks,
        };

        Rest.get(url, content).then(
            (data: { running: Task[]; finished: Task[]; scheduled: Task[] }) => {
                if (params.onFirstLoad && !params.afterBase) {
                    params.onFirstLoad(data);
                }
                let toSet: Task[] = [];
                data.finished.forEach(function (task) {
                    const prev = Base.get(Task, task.id);
                    if (!prev || prev.state !== task.state) {
                        toSet.push(task);
                    }
                    lastFinished = Math.max(lastFinished, task.finished);
                });
                toSet = toSet.concat(data.running);
                toSet = toSet.concat(data.scheduled);
                Base.set(Task, toSet);
                if (params.onFirstLoad && params.afterBase) {
                    params.onFirstLoad(data);
                }
                const interval = (data.running.length > 0 ? 3 : 10) * C.SEC;
                const recurParams = { ...params };
                recurParams.onFirstLoad = null;
                recurParams.afterBase = false;
                // Polled calls do not pass onFirstLoad, so it is only called once.
                const timeout = setTimeout(
                    whenPageVisible(() => Task.loadTasks(recurParams)),
                    interval,
                );
                if (params.simpleLoad) {
                    simplePriorityLoadTask = timeout;
                }
            },
            () => {
                const recurParams = { ...params };
                recurParams.afterBase = false;
                // Polled calls do pass onFirstLoad, since we will want an unsuccessful first load to
                // try again and then call onFirstLoad if it is successful. If an error has occurred on
                // a subsequent loadTasks call, then onFirstLoad is undefined, anyway.
                const timeout = setTimeout(
                    whenPageVisible(() => Task.loadTasks(recurParams)),
                    C.MIN,
                );
                if (params.simpleLoad) {
                    simplePriorityLoadTask = timeout;
                }
            },
        );
    }

    interface FinishCallback {
        (data: Task): void;
    }
    var taskListeners: { [id: string]: FinishCallback[] } = {};

    export interface TaskResponse<T> {
        task: Task;
        data: T;
        // One of message or msg should be defined.
        message?: string;
        msg?: string;
    }

    export interface Callback {
        (data: TaskResponse<unknown>, message: string): void;
    }

    class TaskCreationNotifier {
        notify(
            data: TaskResponse<unknown>,
            message: string,
            success?: Callback,
            onFinish?: FinishCallback,
        ) {
            const task = Base.set(Task, data.task);
            this.makeNotificationBox(task, [Dom.p(Str.ellipsify(message, 120))]);
            success && success(data, message);
            onFinish && registerTaskListener(task.id, onFinish);
        }
        protected makeNotificationBox(task: Task, msg: Dom.Content[]) {
            if (this.shouldIncludeLink(task)) {
                let home: string;
                if (task.project) {
                    const project: Project = Base.get(Project, task.project);
                    if (project.suspended) {
                        home = this.getSuspendedUrl();
                    } else {
                        home = `${"/" + task.project.toString() + "/"}${this.getUrl()}`;
                    }
                } else {
                    home = this.getUrl();
                }
                msg.push(Dom.p(this.getLinkContent(home)));
            }
            addToastWrapper({ icon: "InfoCircle", title: "Task started", body: msg });
        }
        protected shouldIncludeLink(task: Task) {
            return (
                !Util.onHomePage() && (!Util.onAdminOrSuperuserPage() || Is.defined(task.project))
            );
        }
        protected getUrl() {
            return "home.do";
        }
        protected getSuspendedUrl() {
            // export notifications for suspended projects should link to the OA tasks page
            return "org.do#tab=tasks";
        }
        protected getPageName(): Dom.Content {
            return "Project Home";
        }
        protected getSuspendedPageName(): Dom.Content {
            // export notifications for suspended projects should link to the OA tasks page
            return "Tasks";
        }
        protected getLinkContent(url: string) {
            let pageName: Dom.Content;
            if (url === this.getSuspendedUrl()) {
                pageName = this.getSuspendedPageName();
            } else {
                pageName = this.getPageName();
            }
            return [
                "Visit ",
                Dom.a({ href: url, tabindex: "-1" }, pageName),
                " to monitor progress and view results when the task completes.",
            ];
        }
    }

    const taskCreationNotifier = new (SbFree.mixin(TaskCreationNotifier, {
        Base: class extends TaskCreationNotifier {
            protected override shouldIncludeLink(task: Task) {
                return super.shouldIncludeLink(task) && task.hasFile;
            }
            protected override getUrl() {
                return "data.do#tab=exports";
            }
            protected override getPageName(): Dom.Content {
                return "Exports";
            }
        },
    }))();

    export function taskCreationNotification(
        data: TaskResponse<unknown>,
        success?: Callback,
        onFinish?: FinishCallback,
    ) {
        taskCreationNotifier.notify(data, data.message || data.msg, success, onFinish);
    }

    interface CreateParams {
        onFinish?: FinishCallback;
        success?: Callback;
        error?: (data: any, msg: string, status: number) => void;
        errorMessage?: (msg: string) => void;
    }

    /**
     * Send a task request to the server and display a dialog indicating success/failure.
     * In the URL, don't confuse "/tasks/" with "tasks/": /tasks/ (handled by TaskController)
     * contains task management functions and functions to start global tasks. Most tasks are local
     * to a project and as such are started by a URL under /{projectId}/tasks/ (handled by
     * ProjectTaskController), which may be specified with a relative URL of tasks/.
     *
     * Special parameters here;
     * @params.onFinish Callback if the task finishes and this window gets a notification (note
     *     that
     * there's no guarantee that this callback will get called, even so - the task may finish so
     *     quickly that we get the notification before the callback gets registered) Other
     *     Rest-style parameters will also get passed through.
     *
     */
    export function createTask(url: string, task_args: any = {}, extraParams: CreateParams = {}) {
        var wrappedSuccess = extraParams.success;
        var wrappedFinish = extraParams.onFinish;
        delete extraParams.success;
        delete extraParams.onFinish;
        Rest.post(url, task_args).then(
            (data) => {
                taskCreationNotification(data, wrappedSuccess, wrappedFinish);
            },
            (e) => {
                extraParams.error && extraParams.error(e.data, e.message, e.status);
                if (extraParams.errorMessage) {
                    extraParams.errorMessage(e.message);
                } else {
                    throw e;
                }
            },
        );
        return true;
    }

    export function registerTaskListener(taskId: number, onFinish: FinishCallback) {
        if (!(taskId in taskListeners)) {
            taskListeners[taskId] = [];
        }
        taskListeners[taskId].push(onFinish);
    }

    export function completionHandler(taskJson: any) {
        var task = new Task(taskJson);
        if (task.state === Task.COMPLETED) {
            var body: Dom.Content = Str.ellipsify(task.summary, 120);
            var hasDownload = task.hasFile || task.hasDetails();
            if (task.hasFile) {
                body = Dom.a(
                    {
                        href: task.resultLink(),
                        target: "_blank",
                    },
                    body,
                );
            } else if (task.hasDetails()) {
                body = Dom.a(
                    {
                        href: task.detailsLink(),
                        target: "_blank",
                    },
                    body,
                );
            }

            let title = task.isAsyncTask ? "Task Initialized" : "Task Completed";
            if (task.isCloneProjectTask && task.dependencyErrorCount) {
                title = "Task partially completed";
                body = Dom.div(
                    body,
                    Dom.div(
                        { style: { marginTop: "8px" } },
                        "See task details for more information",
                    ),
                );
            }
            // When SbFree users add documents to their project, the documents are automatically
            // added to the project's story. The "Modify X documents: Add Story: Project Story"
            // toast that accompanies the BatchMutateTask to do this would be confusing to
            // users, so we omit it.
            const addToStoryRegex = /Modify [0-9]+ document[s]?: Add Story/g;
            if (!SbFree.inContext() || task.summary.match(addToStoryRegex) === null) {
                // If the task has no download, then the type will be SUCCESS which will override the
                // Download icon.
                addToastWrapper({
                    icon: "Download",
                    title,
                    body,
                    type: hasDownload ? ToastType.STANDARD : ToastType.SUCCESS,
                });
            }
        } else if (task.state === Task.STOPPED) {
            addToastWrapper({
                title: "Task stopped by user",
                body: Dom.div([task.summary + ": ", Dom.br(), Dom.br(), task.errorMessage]),
                type: ToastType.ERROR,
            });
        } else {
            addToastWrapper({
                title: "Task failed to complete",
                body: task.summary + ": " + task.errorMessage,
                type: ToastType.ERROR,
            });
        }
        if (task.id in taskListeners) {
            taskListeners[task.id].forEach(function (f) {
                f(task);
            });
            delete taskListeners[task.id];
        }
    }
    export interface TaskExecution {
        eta: number;
        progress: number;
        id: string;
    }

    function exportOrDeleteInfo(t: Task) {
        const lines = [];
        lines.push(
            "Time started: "
                + DateUtil.displayShortDateLocal(t.created)
                + ", "
                + DateUtil.displayTimeLocal(t.created),
        );
        lines.push(
            "Time completed: "
                + DateUtil.displayShortDateLocal(t.finished)
                + ", "
                + DateUtil.displayTimeLocal(t.finished),
        );
        lines.push("Task duration: " + Duration.HR_MIN_SEC.format(t.finished - t.created));
        // If the billable size of a deletion hasn't been calculated, billableSize is undefined
        if (t.hasDeleteTaskInfo() && Is.defined(t.billableSize)) {
            const billableSize = Util.displayFileSize(t.billableSize, false, 3, undefined, false);
            lines.push("Data size deleted: " + billableSize);
        }
        return lines;
    }

    export function showDetailsDialog(t: Task) {
        if (!t.isFinished()) {
            return;
        }
        const toDestroy: Util.Destroyable[] = [];
        const body = Dom.div({ class: "task-details-dialog" });
        const title = Dom.h3({ class: "h2" }, t.display());
        const user = t.user != null && Base.get(User, t.user);
        const userDisplay = user ? Util.ellipsify(user.display(), 27) : "Unknown user";
        const taskProject = t.project != null && Base.get(Project, t.project);
        const projectDisplay = taskProject ? taskProject.display() : "Unknown project";
        const userDateProjectContainer = Dom.div(
            { class: "user-date-project-container" },
            userDisplay,
            " • ",
            DateUtil.displayShortDateLocal(t.finished)
                + ", "
                + DateUtil.displayTimeLocal(t.finished),
            " • ",
            "Project: " + projectDisplay,
        );
        const stateWrapper = Dom.div({ class: "task-state-wrapper" });
        let stateIcon = null;
        if (t.isStopped()) {
            stateIcon = new LabeledIcon("x-circle-filled-red-20", {
                label: "Aborted",
                class: "task-details-error",
            });
        } else if (!t.isError() && t.dependencyErrorCount > 0) {
            stateIcon = new LabeledIcon("alert-circle-filled-yellow-20", {
                label: "Partial Success",
                class: "task-details-success",
            });
        } else if (
            (t.isError() && t.isCloneProjectTask)
            || (!t.isError() && t.errorCount > 0 && t.taskType === "DocumentExportTask")
        ) {
            // TODO: We should have an an actual state for "completed with error", or somehow
            // generalize this more so we don't need specific task names and conditions.
            stateIcon = new LabeledIcon("alert-circle-filled-yellow-20", {
                label: "Completed with error",
                class: "task-details-error",
            });
        } else if (t.isError() || t.errorCount !== 0) {
            let errorLabel = "Failure";
            if (t.successCount > 0) {
                errorLabel = "Partial Failure";
            }
            stateIcon = new LabeledIcon("x-circle-filled-red-20", {
                label: errorLabel,
                class: "task-details-error",
            });
        }
        if (stateIcon) {
            Dom.place(stateIcon.node, stateWrapper);
        }
        const hasDetails = t.hasDetails();
        const detailsMessage = hasDetails ? t.details.join("\n") : "";
        const hasErrorMessage = !!t.errorMessage;
        let messageText = "";
        if (t.hasDeleteTaskInfo() || t.hasExportTaskInfo()) {
            messageText = exportOrDeleteInfo(t).join("\n");
        }
        if (hasDetails) {
            messageText += "\n" + detailsMessage;
        } else if (hasErrorMessage) {
            messageText += "\n" + t.errorMessage;
        }
        // We handle showing size info here so they display after "stopped by" messages on aborted tasks.
        if (t.hasExportTaskInfo()) {
            messageText += "\n"; // add blank line for spacing
            // The totalUncompressedSize field on a export can be 0 for a couple reasons:
            //   1. Loading the sizes failed for some reason when creating the export
            //   2. The export only contains load files or work product, which we don't calculate
            //      sizes for
            // In either case, it's not helpful to the user to display an obviously wrong file size
            // of 0, so we don't display anything at all.
            if (Is.defined(t.totalUncompressedSize) && t.totalUncompressedSize > 0) {
                const totalSize = Util.displayFileSize(t.totalUncompressedSize);
                messageText += "\nTotal document size (uncompressed): " + totalSize;
            }
            if (Is.defined(t.downloadFileSize)) {
                const exportSize = Util.displayFileSize(t.downloadFileSize);
                messageText += "\nExport size: " + exportSize;
            }
        }
        if (hasDetails && hasErrorMessage) {
            Dom.create(
                "span",
                { class: "task-error-message", textContent: t.errorMessage },
                stateWrapper,
            );
        }
        const messageContainer = Dom.div(
            { class: "task-details-message-container" },
            Dom.div({
                class: "task-details-message",
                textContent: messageText,
            }),
        );

        let warning = Dom.div();
        if (t.dependencyErrorCount > 0) {
            const icon = new Icon("info-circle-20");
            warning = Dom.div(
                { class: "task-details-footer" },
                Dom.node(icon),
                Dom.span(
                    { class: "task-details-warning-label" },
                    "Some settings failed to copy because they depend "
                        + "on missing or excluded objects or settings",
                ),
            );
            toDestroy.push(icon);
        }

        if (t.detailPreviewFlag) {
            const previewDetailsHeader = Dom.span(
                { class: "task-details-preview-header" },
                "Preview of details",
            );
            const onClick = () => ga_event("Tasks", "DownloadDetails", t.taskType);
            const downloadLink = ActionNode.textAction(
                "Download full details",
                onClick,
                null,
                t.detailsLink(),
            );
            toDestroy.push(downloadLink);
            Dom.addClass(downloadLink, "details-download-link");

            Dom.place(
                [
                    title,
                    userDateProjectContainer,
                    stateWrapper,
                    warning,
                    Dom.div(
                        { class: "task-details-expander-section" },
                        Dom.node(downloadLink),
                        Dom.node(previewDetailsHeader),
                        messageContainer,
                    ),
                ],
                body,
            );
        } else {
            Dom.place(
                [title, userDateProjectContainer, stateWrapper, warning, messageContainer],
                body,
            );
        }
        let titleType = "Batch action";
        if (t.hasExportTaskInfo()) {
            titleType = "Export";
        } else if (t.hasDeleteTaskInfo()) {
            titleType = "Batch deletion";
        }
        new Dialog({
            title: titleType + " details",
            content: body,
            onHide: () => Util.destroy(toDestroy),
            style: { width: "576px" },
        }).show();
        if (title.offsetWidth < title.scrollWidth) {
            toDestroy.push(new Tooltip(title, t.display()));
        }
    }
}
export = Task;
